<template>
    <div class="container pb-5">
        <Spinner v-if="loading" />
        <table class="table table-hover" v-else>
            <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Is Entrepreneur</th>
                    <th scope="col">Code</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, key) in users" :key="key">
                    <td>{{ user.id }}</td>
                    <td>{{ user.first_name }} {{ user.last_name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.is_entrepreneur ? "Yes" : "No" }}</td>
                    <td>{{ user.code }}</td>
                    <td>
                        <router-link :to="{ name: 'AdminUserDetail', params: { id: user.id } }">
                            View
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
        <br />
        <br />
    </div>
</template>
  
<script>
import axios from 'axios';
import Spinner from '../../components/ui/Spinner.vue';

export default {
    components: { Spinner },
    data() {
        return {
            loading: false,
            users: []
        }
    },
    created() {
        console.log("created");
    },
    mounted() {
        console.log("mounted");
        this.getUsers();
    },
    methods: {
        async getUsers() {
            this.loading = true;
            const { data } = await axios.get('admin/users/list');
            this.users = data.users;
            this.loading = false;
        }
    }
};
</script>
  